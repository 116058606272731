import React, { useEffect } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { withStyles } from "@material-ui/core/styles";
import { StepLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import StepConnector, {
	stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import SingleOrderPickupModal from "./SingleOrderPickupModal";
import { NavLink, Redirect } from "react-router-dom";
import { IZeoosOrder } from "../../../../../../interfaces";
import {
	sendPurchaseOrder,
	schedulePickup,
	createCustomerInvoice,
	saveCustomerInvoice,
	downloadCustomerInvoice,
	downloadShippingLabel,
} from "../../../../../../actions/order";
import { toast } from "react-toastify";
import RoleRequired from "../../../../../utils/role-required/RoleRequired";
import { Role } from "../../../../users-management/UsersManagement";
// import useUser from "../../../../../../hooks/useUser";

// const stepsAdmin = ['Order Approved', 'Pickup Scheduling', 'Customer Invoice', 'Confirm Shipping','Delivered']
// const stepsSeller = ['Order Approved', 'Purchase Order', 'Pickup Scheduling', 'Customer Invoice', 'Confirm Shipping', 'Delivered']

const AntStepLabel = withStyles({
	root: {
		display: "flex !important",
		flexDirection: "column-reverse",
		alignItems: "center",
		justifyContent: "center",
		textAlign: "center",
	},
	iconContainer: {
		padding: "10px !important",
		color: "#e2e2e2 !important",
	},
})(StepLabel);

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 20,
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			background: "#E1E1E1",
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			background: "#E1E1E1",
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		marginTop: "-15px",
		height: 3,
		border: 0,
		backgroundColor:
			theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
		borderRadius: 1,
	},
}));

const ColorlibStepIconRoot = styled("div")<{
	ownerState: { completed?: boolean; active?: boolean; };
}>(({ theme, ownerState }) => ({
	backgroundColor: "#F2F2F2",
	zIndex: 1,
	color: "#f2f2f2",
	width: 18,
	height: 18,
	display: "flex",
	borderRadius: "50%",
	justifyContent: "center",
	alignItems: "center",
	border: "1px solid #E1E1E1",
	...(ownerState.active && {
		background: "#FFFFFF",
		border: "1px solid #5a5a5a",
	}),
	...(ownerState.completed && {
		background: "#DAF2DB",
		border: "1px solid #588E44",
	}),
}));
const ColorlibStepInfoRoot = styled("div")<{
	ownerState: { completed?: boolean; active?: boolean; };
}>(({ theme, ownerState }) => ({
	display: "none",
	...(ownerState.active && {
		display: "flex",
	}),
	...(ownerState.completed && {
		display: "none",
	}),
}));

//   function ColorlibStepIconAdmin(props: StepIconProps) {
//     const { active, completed, className } = props;

//     const icons: { [index: string]: React.ReactElement } = {
//         1: <></>,
//         2: <SingleOrderPickupModal />,
//         3: <button className="so-blue-btn">
//               <img className='mr1' src="/icons/invoice-white.svg" alt="" />
//               Print Customer Invoice
//           </button>,
//         4: <p className='so-alert-text'>Waiting confirmation</p>,
//         5: <></>,
//       };

//     return (
//         <div className='in-column align-center justify-between minheight70'>
//             <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
//             </ColorlibStepIconRoot>
//             <ColorlibStepInfoRoot ownerState={{ completed, active }}>
//                 {icons[String(props.icon)]}
//             </ColorlibStepInfoRoot>
//         </div>
//     );
//   }

//   function ColorlibStepIconSeller(props: StepIconProps) {
//     const { active, completed, className } = props;

//     const icons: { [index: string]: React.ReactElement } = {
//         1: <></>,
//         2: <button className="so-blue-btn">
//               <img className='mr1' src="/icons/invoice-white.svg" alt="" />
//               Send Purchase Order
//           </button>,
//         3: <p className='so-alert-text'>Waiting confirmation</p>,
//         4: <button className="so-blue-btn">
//               <img className='mr1' src="/icons/invoice-white.svg" alt="" />
//               Create Customer Invoice
//             </button>,
//         5: <p className='so-alert-text'>Waiting confirmation</p>,
//         6: <></>,
//       };

//     return (
//         <div className='in-column align-center justify-between minheight70'>
//             <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
//             </ColorlibStepIconRoot>
//             <ColorlibStepInfoRoot ownerState={{ completed, active }}>
//                 {icons[String(props.icon)]}
//             </ColorlibStepInfoRoot>
//         </div>
//     );
//   }

type TParams = {
	order: IZeoosOrder;
	shipping: string;
	steps: any;
	actions: any;
};

export const downloadBlob = (
	file: string,
	fileName: string,
	mimeType: string
) => {
	const data = Uint8Array.from(atob(file), (c) => c.charCodeAt(0));
	const blob = new Blob([data], { type: mimeType });

	// Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
	const blobUrl = URL.createObjectURL(blob);

	// Create a link element
	const link = document.createElement("a");

	// Set link's href to point to the Blob URL
	link.href = blobUrl;
	link.download = fileName;

	// Append link to the body
	document.body.appendChild(link);

	// Dispatch click event on the link
	// This is necessary as link.click() does not work on the latest firefox
	link.dispatchEvent(
		new MouseEvent("click", {
			bubbles: true,
			cancelable: true,
			view: window,
		})
	);

	// Remove link from body
	document.body.removeChild(link);
};

const SingleOrderViewStepper: React.FC<TParams> = ({
	order,
	shipping,
	steps,
	actions,
}) => {
	// const { user } = useUser();

	const [activeStep, setActiveStep] = React.useState(0);
	const [actionIcon, setActionIcon] = React.useState({} as any);
	const [pickupModal, setPickupModal] = React.useState(false);
	const [redirectPurchase, setRedirectPurchase] = React.useState(false);
	const [buttonStatus, setButtonStatus] = React.useState(false);

	const shippingFiltered = order.shipping_list?.filter(
		(s: any) => s._id === shipping
	)[0];
	const stepFiltered = steps.filter(
		(s: any) => s.id === shippingFiltered._id
	)[0];
	const actionFiltered = actions.filter(
		(a: any) => a.id === shippingFiltered._id
	)[0];

	// const handleStep = (step: number) => () => {
	//     setActiveStep(step);
	// };

	const handleAction = async () => {
		setButtonStatus(true);

		if (actionIcon.action === "send_purchase") {
			const purchase = await sendPurchaseOrder(order._id, shippingFiltered._id);
			if (purchase.success) {
				setActionIcon({
					index: actionIcon.index,
					action: null,
					label: "Waiting Confirmation",
				});
				toast.success(purchase.message);
			} else {
				toast.error(purchase.message, { autoClose: false });
			}
		}

		if (actionIcon.action === "confirm_purchase") {
			setRedirectPurchase(true);
		}

		if (actionIcon.action === "create_shipping_label") {
			setPickupModal(true);
		}

		if (actionIcon.action === "create_customer_invoice") {
			const invoice = await createCustomerInvoice(
				order._id,
				shippingFiltered._id
			);
			if (invoice.success) {
				setActionIcon({
					index: actionIcon.index,
					action: null,
					label: "Waiting Confirmation",
				});
				toast.success(invoice.message);
			} else {
				toast.error(invoice.message, { autoClose: false });
			}
		}

		if (actionIcon.action === "save_customer_invoice") {
			console.log(shippingFiltered.draft_invoice);
			const invoice = await saveCustomerInvoice(shippingFiltered.draft_invoice);
			if (invoice.success) {
				setActionIcon({
					index: actionIcon.index,
					action: null,
					label: "Waiting Confirmation",
				});
				const { file, fileName, mimeType } = invoice.download;
				downloadBlob(file, fileName, mimeType);
			} else {
				toast.error(invoice.message, { autoClose: false });
			}
		}

		setButtonStatus(false);
	};

	useEffect(() => {
		setActiveStep(
			stepFiltered.timeline.findIndex(
				(s: any) => s.value === shippingFiltered.timeline
			) + 1
		);
		setActionIcon({
			index:
				stepFiltered.timeline.findIndex(
					(s: any) => s.value === actionFiltered.timeline
				) + 1,
			label: actionFiltered.label,
			action: actionFiltered.action,
		});
	}, [shippingFiltered, stepFiltered, actionFiltered]);

	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setPickupModal(false);
	};

	const onModalSubmit = async (
		volume: string,
		weight: string,
		pickupDay: string
	) => {
		const pickup = await schedulePickup({
			order: order._id,
			shipping: shippingFiltered._id,
			volume,
			weight,
			date: pickupDay,
		});

		if (pickup.success) {
			setActionIcon({
				index: actionIcon.index,
				action: null,
				label: "Waiting Confirmation",
			});
			setPickupModal(false);
			toast.success(pickup.message);
		} else {
			toast.error(pickup.message, { autoClose: false });
		}
	};

	const handleDownloadInvoice = async () => {
		const invoice = await downloadCustomerInvoice(shippingFiltered.invoice);
		if (invoice.success) {
			const { file, fileName, mimeType } = invoice.download;
			downloadBlob(file, fileName, mimeType);
		} else {
			toast.error(invoice.message, { autoClose: false });
		}
	};

	const handleDownloadShippingLabel = async () => {
		const label = await downloadShippingLabel(shippingFiltered.reference);
		if (label.success) {
			const { file, fileName, mimeType } = label.download;
			downloadBlob(file, fileName, mimeType);
		} else {
			toast.error(label.message, { autoClose: false });
		}
	};

	function ColorlibStepIcon(props: StepIconProps) {
		const { active, completed, className } = props;

		const icons: { [index: string]: React.ReactElement; } = {
			[actionIcon.index]: actionIcon.action ? (
				<button
					className={`so-blue-btn icon ${actionIcon.action}`}
					onClick={handleAction}
					disabled={buttonStatus}
				>
					{actionIcon.label}
				</button>
			) : (
				<p className="so-alert-text">{actionIcon.label}</p>
			),
		};

		return (
			<div className="in-column align-center justify-between minheight70">
				<ColorlibStepIconRoot
					ownerState={{ completed, active }}
					className={className}
				></ColorlibStepIconRoot>
				<ColorlibStepInfoRoot ownerState={{ completed, active }}>
					{icons[String(props.icon)]}
				</ColorlibStepInfoRoot>
			</div>
		);
	}

	if (order.marketplace === "B2B") return <></>;

	return (
		<>
			{redirectPurchase ? (
				<Redirect to={`/orders/${order._id}/${shipping}/purchase`} />
			) : null}
			{pickupModal && (
				<SingleOrderPickupModal
					shipping={shippingFiltered}
					onModalClose={onModalClose}
					onModalSubmit={onModalSubmit}
				/>
			)}
			<div className="single-order-cont">
				<div className="single-order-title-cont">
					<div className="in-row align-center">
						<h2 className="single-order-title">
							{shippingFiltered.reference || shippingFiltered.type} |{" "}
						</h2>
						<span className="ml2"> Order View {order.order_id}</span>
					</div>
					{/* <RoleRequired role={Role.admin}> */}
					<div className="in-row align-center">
						{/* TODO */}
						{/*
                        <img className='mr2 pointer' src='/icons/order-refresh.svg' alt='' />
                        <img className='mr2 pointer' src='/icons/order-print.svg' alt='' />
                        */}
						{shippingFiltered.purchase_order && (
							<NavLink
								className="in-row align-center"
								to={`${shipping}/purchase`}
							>
								<img
									className="mr2 pointer"
									src="/icons/order-invoice.svg"
									alt=""
									title="Purchase Order"
								/>
							</NavLink>
						)}
						{shippingFiltered.tracking && (
							<img
								className="mr2 pointer"
								src="/icons/so-tag-print.svg"
								title="Print shipping label"
								alt=""
								onClick={handleDownloadShippingLabel}
							/>
						)}
						{shippingFiltered.invoice && (
							<img
								className="mr2 pointer"
								src="/icons/so-invoice.svg"
								title="Print invoice"
								alt=""
								onClick={handleDownloadInvoice}
							/>
						)}
					</div>
					{/* </RoleRequired> */}
				</div>
				<div className="order-border"></div>
				{/* TODO */}
				{/* <span className='pt2 table-subtext underline pointer'>Cancel Order</span> */}
				<div className="width100 mt4">
					<Stepper activeStep={activeStep} connector={<ColorlibConnector />}>
						{stepFiltered.timeline.map((step: any, index: number) => (
							<Step key={step.label}>
								<AntStepLabel
									className="pointer"
									StepIconComponent={ColorlibStepIcon}
								>
									{step.label}
								</AntStepLabel>
							</Step>
						))}
					</Stepper>
				</div>
			</div>
		</>
	);
};

export default SingleOrderViewStepper;
