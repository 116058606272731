import React, { useState } from "react";
import { useQuery } from "react-query";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { getSchemes } from "../../../actions/pim";
import { CategoryFieldController } from "../../../actions";
import { IMarketplace } from "../../../interfaces";
import { ICategoryField } from "types";
import {
	Autocomplete,
	Box,
	CircularProgress,
	Menu,
	MenuItem,
	TextField,
} from "@mui/material";
import { useMemo } from "react";
import { useCallback } from "react";
import { queryClient } from "../../App";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ModalHeader from "./ModalHeader";
import useMarketplace from "../../../hooks/useMarketplace";
import { getMarketplaceCategories } from "../../../actions/marketplace";
import { compact, isEmpty } from "lodash";
import useMarketplaceCategory from "../../../hooks/useMarketplaceCategory";
import { useDeferredValue } from "react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

type TParams = { zeoosName: string };

const ProductAttrubutes: React.FC<RouteComponentProps<TParams>> = (props) => {
	const zeoosName = useMemo(() => props.match.params.zeoosName, [props]);
	const key = useMemo(
		() => `/category-fields?zeoosName=${zeoosName}`,
		[zeoosName]
	);
	const marketplace = useMarketplace(zeoosName);

	const marketplaceCategories = useQuery(
		`/marketplace/${zeoosName}/categories`,
		() => getMarketplaceCategories(zeoosName),
		{ refetchOnWindowFocus: false }
	);
	const { isLoading, data } = useQuery(
		key,
		() =>
			CategoryFieldController.find({
				marketplaceName: marketplace.data?.marketplaceName!,
			}),
		{
			enabled: !!marketplace.data,
		}
	);

	const mutate = useCallback(() => {
		queryClient.invalidateQueries(key);
	}, [key]);

	const [toAddNew, setToAddNew] = useState(false);

	if (isLoading || marketplaceCategories.isLoading) {
		return <></>;
	}

	return (
		<div className="product-main-cont">
			<ModalHeader zeoosName={zeoosName} />

			<div className="product-cont">
				<h1 className="product-h">Categorise your products</h1>
				<p className="product-p">
					Assign platform category to PIM schema to match attribute fields.
				</p>
				<div className="product-content">
					<div className="product-box">
						<span className="product-content-title width100">
							{marketplace.data?.zeoosName} categories
						</span>
						<span className="product-content-title width60 text-aligns ml4">
							PIM schemes
						</span>
					</div>

					{data?.map((categoryField) => (
						<CreateEditCategoryField
							key={categoryField.scheme}
							marketplace={marketplace.data!}
							categoryField={categoryField}
							onSuccess={mutate}
							categories={marketplaceCategories?.data || []}
							loading={marketplaceCategories.isLoading}
						/>
					))}

					{toAddNew && (
						<CreateEditCategoryField
							marketplace={marketplace.data!}
							onSuccess={mutate}
							categories={marketplaceCategories?.data || []}
						/>
					)}

					<div className="product-box">
						<div
							className="product-add-cont-order mt2"
							onClick={() => setToAddNew((prev) => !prev)}
						>
							<img
								className="mr2"
								src="/icons/product-add-circle-blue.svg"
								alt=""
							/>
							{toAddNew ? "REMOVE THE CATEGORY" : "ADD A NEW CATEGORY"}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

interface ICategory {
	id: number | string;
	name: string;
}

const CreateEditCategoryField: React.FC<{
	marketplace: IMarketplace;
	categoryField?: ICategoryField;
	onSuccess: Function;
	categories?: ICategory[];
	loading?: boolean;
}> = ({ marketplace, categoryField, onSuccess, categories, loading }) => {
	const [categoryName, setCategoryName] = useState(categoryField?.name || "");
	const [scheme, setScheme] = useState<string>(categoryField?.scheme || "");
	const { isLoading, data: schemes } = useQuery("schemes", () => getSchemes());
	const [editMode, setEditMode] = useState(!categoryField);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const debouncedCategoryName = useDeferredValue(categoryName);

	const marketplaceCategory = useMarketplaceCategory(
		marketplace.zeoosName,
		debouncedCategoryName,
		{
			enabled: !!categories?.length && editMode,
			refetchOnWindowFocus: false,
		}
	);

	return (
		<div className="product-box">
			<div className="product-input-cont width100">
				<span className="mwidth120">Category</span>
				{categories?.length ? (
					<>
						<Autocomplete
							value={categoryName}
							freeSolo
							className="mwidth220 mr3"
							onChange={(e, value) => {
								setCategoryName(value || "");
							}}
							renderInput={(params) => <TextField {...params} />}
							options={compact([
								categoryField?.name,
								...categories?.map((c: ICategory) => c.name),
							])}
							disabled={!editMode}
						/>

						{editMode && (
							<Box sx={{ ml: 1 }}>
								{marketplaceCategory.isLoading ? (
									<CircularProgress size={28} />
								) : isEmpty(marketplaceCategory.data) ? (
									<CloseIcon sx={{ color: "#ff8383" }} />
								) : (
									<CheckIcon sx={{ color: "#00a224" }} />
								)}
							</Box>
						)}
					</>
				) : (
					<input
						className="product-input"
						placeholder={`Category name`}
						value={categoryName}
						onChange={(e) => setCategoryName(e.target.value)}
						disabled={!editMode}
					/>
				)}
			</div>
			<img className="ml2 mr2" src="/icons/product-arrow-right.svg" alt="" />
			<div className="product-input-cont width60">
				{!isLoading && (
					<TextField
						onChange={(e) => setScheme(e.target.value)}
						value={scheme}
						select
						sx={{
							minWidth: 300,
						}}
						inputProps={{
							className: "product-select",
						}}
						disabled={!editMode}
					>
						{schemes?.map((scheme, index: number) => (
							<MenuItem value={scheme._id} className="compa-option" key={index}>
								{scheme.name}
							</MenuItem>
						))}
					</TextField>
				)}

				{categoryField && !editMode ? (
					<Link
						to={`/match-fields/${categoryField?._id}/${marketplace.zeoosName}`}
						className="product-main-btn"
					>
						match
					</Link>
				) : (
					<button
						className="product-main-btn"
						onClick={async () => {
							if (categoryField) {
								await CategoryFieldController.update(categoryField._id, {
									name: categoryName,
									scheme,
								});

								setEditMode(false);
							} else {
								await CategoryFieldController.create({
									marketplaceName: marketplace.marketplaceName,
									name: categoryName,
									scheme,
									categoryId: categories?.find(
										(c: ICategory) => c.name === categoryName
									)?.id,
								});
							}

							onSuccess();
						}}
					>
						Save
					</button>
				)}

				{categoryField && (
					<MoreVertIcon
						fontSize="small"
						sx={{ cursor: "pointer" }}
						onClick={handleClick}
					/>
				)}

				<Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
					<MenuItem
						onClick={async () => {
							handleClose();
							setEditMode((prev) => {
								if (prev && categoryField) {
									setCategoryName(categoryField.name);
									setScheme(categoryField.scheme);
								}

								return !prev;
							});
						}}
					>
						{editMode ? "Discard" : "Edit"}
					</MenuItem>
					<MenuItem
						onClick={async () => {
							if (
								window.confirm(
									`Are you sure you want to delete the category "${categoryName}"`
								)
							) {
								await CategoryFieldController.remove(categoryField?._id!);
								onSuccess();
							}

							handleClose();
						}}
					>
						Delete
					</MenuItem>
				</Menu>
			</div>
		</div>
	);
};

export default withRouter(ProductAttrubutes);
