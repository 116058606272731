import React, { useRef, useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import "./header.css";
import { Link } from "react-router-dom";
import useUser from "../../../hooks/useUser";
import LogoutWrap from "../../utils/logout-wrap/LogoutWrap";
import { UpdateProfile } from "./UpdateProfile";
import { NotiesSection } from "./NotiesSection";
import Avatar from "@mui/material/Avatar";
import { TicketNoties } from "./TicketNoties";

type PathType = {
	path: string;
	label: string;
};

const labels: Array<PathType> = [
	{ path: "/password-management", label: " Settings / Password Management" },
	{ path: "/payouts", label: " Payouts" },
	{ path: "/dashboard", label: "Dashboard" },
	{ path: "/platforms", label: " Platforms" },
	{ path: "/platform/", label: " Platforms " },
	{ path: "/offers", label: " Offers" },
	{ path: "/seller-information", label: " Seller Information" },
	{ path: "/comparador", label: " Price comparison" },
	{ path: "/orders", label: " Orders" },
	{ path: "/long-product-page", label: " Long Product Page" },
	{ path: "/singleorder", label: "Single Order" },
	{ path: "/singleorderview", label: "Single Order View" },
	{ path: "/singleorderpurchase", label: "Single Order Purchase" },
	{ path: "/profile", label: "Profile" },
	{ path: "/users-management", label: " Settings / User Management" },
	{ path: "/reviews", label: " Settings / Reviews" },
	{ path: "/translator", label: "Translator" },
	{ path: "/requests", label: "Requests" },
	{ path: "/tax", label: "Tax Mapping" },
	{ path: "/pricing-event", label: "Discount" },
	{ path: "/notifications", label: "Notifications" },
	{
		path: "/single-notification",
		label: " Notifications / Manual Notification",
	},
	{
		path: "/notifications-settings",
		label: "Settings / Notifications Settings ",
	},
	{ path: "/tickets", label: " Support / Tickets" },
	{ path: "/ticket/", label: " Support / Ticket" },
	{ path: "/pricing", label: "Price Campaings" },
	{ path: "/freight", label: "Freight Mapping" },
	{ path: "/platformRates", label: "Platform Rates" },
	{ path: "/pim-management", label: "Pim management" },
	{ path: "/pim/scheme", label: "Pim scheme" },
	{ path: "/country-management", label: " Settings / Country Management" },
	{ path: "/sellers", label: " Sellers" },
	{ path: "/sellers-onboarding", label: " Sellers" },
	{ path: "/sellers-newonboarding", label: " Sellers" },
	{ path: "/productInfo", label: " Pim" },
	{ path: "/file-management", label: "Syncs / File management" },
	{ path: "/products", label: " Products" },
	{ path: "/jobs-panel", label: " Syncs / Jobs Panel" },
	{ path: "/queue", label: " Syncs / Queue Monitor" },
	{ path: "/vendor", label: " Vendor Mapping" },
	{ path: "/intelligence", label: " Intelligence" },
	{ path: "/intelligence/products", label: " Intelligence" },
	{ path: "/intelligence/overview", label: " Intelligence" },
	{ path: "/intelligence/settings", label: " Intelligence" },
	{ path: "/intelligence/settings-match", label: " Intelligence" },
	{ path: "/intelligence/settings-single", label: " Intelligence" },
	{ path: "/digital-assets", label: " Digital Assets" },
	{ path: "/permission-denied", label: " Permission Denied" },
	{ path: "/pim-translation", label: " Settings / PIM Translation" },
	{ path: "/history-log", label: " History Log" },
	{ path: "/batch-list", label: " Batch List" },
	{ path: "/batch/", label: " Syncs / Batch List " },
	{ path: "/logistic", label: " Logistic " },
];

const Header: React.FC<RouteComponentProps<any>> = (props) => {
	const { user } = useUser() as any;
	// const [username, setUsername] = useState<string>(user.username);

	const [modal, setModal] = useState<boolean>(false);
	const [open, setOpen] = useState<boolean>(false);
	const dropdownRef = useRef<HTMLDivElement>(null);

	const handleDropDownFocus = (state: boolean) => {
		setOpen(!state);
	};

	const handleClickOutsideDropdown = (e: any) => {
		if (open && !dropdownRef.current?.contains(e.target as Node)) {
			setOpen(false);
		}
	};
	window.addEventListener("click", handleClickOutsideDropdown);

	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal(false);
	};

	const getRestLabel = (path: string) => {
		if (
			path.includes("/platform/") ||
			path.includes("/batch/") ||
			path.includes("/productInfo/") ||
			path.includes("/pricing-event/") ||
			path.includes("/ticket/")
		) {
			const pathArr = path.split("/");
			return `/ ${pathArr[pathArr.length - 1]}`;
		} else {
			return "";
		}
	};

	const pathname = props.history.location.pathname;
	const label =
		pathname === "/"
			? "Dashboard"
			: `${
					labels.find((x: any) => pathname.includes(x.path))?.label
			  } ${getRestLabel(pathname)}`;

	return (
		<>
			{user ? (
				<div className="navbar-2">
					<div className="container">
						<div className="panel-menu-box">
							<div className="breadcrumbs">
								<Link to="/">
									<img
										className="home-image"
										src="/icons/breadscrumbs-home.svg"
										alt=""
									/>
								</Link>
								<div className="breadcrumbs-box">
									<div className="margin-left5">/</div>
									<div className="margin-left5">{label}</div>
								</div>
							</div>
						</div>
						<div className="header-button-box">
							<TicketNoties />
							<div className="header-circle-cont">
								<NotiesSection />
							</div>
							<div
								className="app-drop-down-container justify-end ml2"
								ref={dropdownRef}
							>
								<button onClick={(e) => handleDropDownFocus(open)}>
									<Avatar
										alt={user.username}
										className="profile-bg-image user-avatar"
										src={
											user.profileImage === undefined ? "df" : user.profileImage
										}
										sx={{ width: 40, height: 40, borderRadius: "50%" }}
									/>
								</button>
								{open && (
									<ul>
										<li
											style={{ cursor: "default" }}
											className="in-row align-center justify-start"
										>
											<Avatar
												alt={user.username}
												className="profile-bg-image user-avatar"
												src={
													user.profileImage === undefined
														? "df"
														: user.profileImage
												}
												sx={{ width: 40, height: 40, borderRadius: "50%" }}
											/>
											<span className="email-text">
												{user.username}
												<br />
												{user.email}
											</span>
										</li>
										<div className="border-bottom" />
										<li
											onClick={() => {
												setModal(true);
											}}
											className="in-row align-center justify-start not-menuitem-cont "
										>
											<span className="email-text">Profile</span>
										</li>
										<Link to="/notifications-settings">
											<li className="in-row align-center justify-start not-menuitem-cont ">
												<span className="email-text">Settings</span>
											</li>
										</Link>
										<LogoutWrap>
											<li className="in-row align-center justify-start not-menuitem-cont ">
												<span className="email-text">Log out</span>
											</li>
										</LogoutWrap>
									</ul>
								)}
							</div>
							{/* <div
								onClick={() => {
									setModal(true);
								}}
								className="in-row align-center justify-between"
							>
								<Avatar
									alt={user.username}
									className="profile-bg-image user-avatar"
									src={
										user.profileImage === undefined ? "df" : user.profileImage
									}
									sx={{ width: 40, height: 40, borderRadius: "50%" }}
								/>
								<div className="profile-box-header pointer">
									Hey, {user.username}
								</div>
							</div> */}
							{/* <div>
								<LogoutWrap>
									<button className="log-in-out-btn-header">
										<div>Logout</div>
									</button>
								</LogoutWrap>
							</div> */}
						</div>
					</div>
					{modal && (
						<UpdateProfile
							modal={modal}
							onModalClose={onModalClose}
							setModal={setModal}
						/>
					)}
				</div>
			) : (
				<></>
			)}
		</>
	);
};

export default withRouter(Header);
