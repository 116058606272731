import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import SwipeableViews from "react-swipeable-views";
import { withStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Costs } from "../costs/Costs";
// import { Testimonials } from "../testemonials/Testimonials";
import { ReviewsPim } from "../reviews/ReviewsPim";
import { Awards } from "../awards/Awards";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Modal } from "../../../ui/modal/Modal";
import {
	getProductInfo,
	saveProductInfo,
	getCombinedProductBySku,
	updateProductMetaInfo,
} from "../../../../actions/marketplace";
import Preloader from "../../../ui/preloader/Preloader";
import { toast } from "react-toastify";
import { ReusableTab } from "../scheme/ReusableTab";
import { getSchemeById, getSchemes } from "../../../../actions/scheme";
import { EditMetaInfoModal, IEditMetaInfoModal } from "../EditMetaInfoModal";
import {
	getAllProductInfoVersions,
	pushProductVariants,
	updateCurrentProductInfo,
	deleteProductInfoVersion,
} from "../../../../actions/pim";
import moment from "moment";
import { CountriesContext } from "../../../context";
import { ICountryM } from "../../country-management/CountryManagement";
import { useMutation, useQuery } from "react-query";
import {
	IPaginatedRequest,
	SchemeTab,
	SchemeTag,
} from "../../../../interfaces";
import { queryClient } from "../../../App";
import Paginator from "../../../ui/pagination";
import * as _ from "lodash";
import RoleRequired from "../../../utils/role-required/RoleRequired";
import { Role } from "../../users-management/UsersManagement";

interface TabPanelProps {
	children?: React.ReactNode;
	dir?: string;
	index: any;
	value: any;
}

const AntTabs = withStyles({
	root: {
		border: "none !important",
		boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2) !important",
		textDecoration: "none !important",
	},
	indicator: {
		backgroundColor: "#fff",
		overflow: "auto",
	},
	// scroller: {
	//   overflow: 'auto !important',
	// },
})(Tabs);

const AntTab = withStyles({
	root: {
		overflow: "auto !important",
		fontWeight: "bold",
	},
	selected: {
		borderBottom: "2px solid #5a5a5a !important",
	},
})(Tab);

function TabsPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography component="div">{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `full-width-tab-${index}`,
		"aria-controls": `full-width-tabpanel-${index}`,
	};
}

export const ProductInfoContext: any = createContext(true);
export const PimLanguageContext: any = createContext(true);
export const PimSchemeDataContext = createContext<{ schemeData: SchemeTab[]; }>({
	schemeData: [],
});

export function useLanguages() {
	const countries = useContext(CountriesContext) as ICountryM[];
	return countries.filter(
		(country: ICountryM) => country.isLang
	) as ICountryM[];
}

export default function FullWidthTabs(props: any) {
	const countries = useContext(CountriesContext) as ICountryM[];
	const langs = useLanguages();

	const theme = useTheme();
	const [value, setValue] = useState(0);
	const [modal, setModal] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);
	const [schemeData, setSchemeData] = useState<SchemeTab[]>([]);
	const [productInfo, setProductInfo] = useState<any>();
	const [pimLanguage, setPimLanguage] = useState<string>("pt");
	const [combinedProduct, setCombinedProduct] = useState<any>();

	const [editMetaForm, setEditMetaForm] = useState(false);
	const [versionsForm, setVersionsForm] = useState(false);

	// TODO: use this instead of props drilling
	// implement same for schemeData
	const productInfoValue = useMemo(
		() => ({ productInfo, setProductInfo }),
		[productInfo, setProductInfo]
	);
	const pimLanguageValue = useMemo(
		() => ({ pimLanguage, setPimLanguage }),
		[pimLanguage, setPimLanguage]
	);
	const schemeDataValue = useMemo(
		() => ({ schemeData, setSchemeData }),
		[schemeData, setSchemeData]
	);

	useEffect(() => {
		async function initializeState() {
			setLoading(true);
			setCombinedProduct(await getCombinedProductBySku(props.sku));
			setLoading(false);
		}

		initializeState();
	}, [props.sku]);

	useEffect(() => {
		async function initializeState() {
			setLoading(true);
			const res = await getProductInfo(props.sku, pimLanguage);
			const schemeById = await getSchemeById(res.productInfo.productType);
			setSchemeData(schemeById.details.tabs);
			setPimLanguage(res?.productInfo?.lang || "");
			const productInfo = res.productInfo || {};
			productInfo.details = productInfo.details || {};
			setProductInfo(productInfo);
			setLoading(false);
		}
		initializeState();
		// eslint-disable-next-line
	}, [pimLanguage, props.sku]);

	const onUpdateCallback = (newData: any) => {
		setCombinedProduct((prevState: any) => {
			if (prevState?.marketplaces) {
				return prevState;
			}

			Object.keys(prevState.marketplaces).forEach((x: any) => {
				if (prevState.marketplaces[x].id === newData.id) {
					prevState.marketplaces[x].price = newData.price;
					prevState.marketplaces[x].stock = newData.stock;
					prevState.marketplaces[x].compareAtPrice = newData.compareAtPrice;
				}
			});

			return prevState;
		});
	};

	const onInputChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>, name: string) => {
			e.preventDefault();
			const details = { ...productInfo.details, [name]: e.target.value };
			setProductInfo((prev: any) => ({ ...prev, details }));
		},
		[setProductInfo, productInfo?.details]
	);

	const onTagsChange = useCallback(
		(
			e: React.ChangeEvent<HTMLInputElement>,
			name: string,
			values: SchemeTag[]
		) => {
			e.preventDefault();
			const details = { ...productInfo.details, [`${name}_tags`]: values };
			setProductInfo((prev: any) => ({ ...prev, details }));
		},
		[setProductInfo, productInfo?.details]
	);

	const onCheckboxListChange = useCallback(
		(attributeName: string, checkboxLabel: string, options: string[]) => {
			let value = productInfo.details[attributeName] as string | string[];

			if (typeof value === "string") {
				value = [value];
			} else if (!value?.length) {
				value = [];
			}

			// Make sure we only save the values from the options list
			value = value.filter((v) => options.includes(v));

			const details = {
				...productInfo.details,
				[attributeName]: value.includes(checkboxLabel)
					? value.filter((x: any) => x !== checkboxLabel)
					: [...value, checkboxLabel],
			};

			setProductInfo((prev: any) => ({ ...prev, details }));
		},
		[setProductInfo, productInfo?.details]
	);

	const onSubmit = async (e: React.MouseEvent) => {
		e.preventDefault();
		const { productInfo: currentProductInDB } = await getProductInfo(
			props.sku,
			pimLanguage
		);

		if (currentProductInDB.version > productInfo.version) {
			toast.error(
				"There is a newer version of this product. Reload your page to prevent potential data loss"
			);
			return;
		}

		schemeData.forEach((tab) => {
			tab.fields.forEach((field) => {
				field.fields.forEach((x) => {
					if (x.tags && x.autoValues) {
						const customTags = productInfo.details[`${x.name}_tags`] || [];
						productInfo.details[x.name] = _.uniqBy(
							[...x.tags, ...customTags],
							"name"
						)
							.filter((tag: SchemeTag) => !tag.disabled)
							.map((tag) => productInfo.details[tag.name])
							.join(", ");
					}
				});
			});
		});

		try {
			const body = {
				sku: props.sku,
				lang: pimLanguage,
				details: productInfo.details,
				isVariant: productInfo.isVariant,
				isMix: productInfo.isMix,
				variantOfSku: productInfo.variantOfSku,
				components: productInfo.components,
				productType: productInfo.productType,
			};

			setProductInfo(await saveProductInfo(body));
			toast.success("The data is successfully saved!");
		} catch (error) {
			console.error(error);
			toast.error("Something goes wrong");
		}
	};

	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setValue(newValue);
	};

	const handleChangeIndex = (index: number) => {
		setValue(index);
	};

	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal(false);
	};

	const tabData = (label: string) => {
		return schemeData?.find((item: SchemeTab) => item.label === label)?.fields;
	};

	const schemeLabels = () => {
		return schemeData?.map((item: SchemeTab) => item.label);
	};

	const antTab = useCallback((arr: SchemeTab[]) => {
		// const tabs = ["Custos", "Avaliações", "Prémios e Citações", "Testemunhos"];
		const tabs = ["Custos", "Avaliações", "Prémios e Citações"];
		const schemeLabels = arr.map((tab) => tab.label);
		return [...schemeLabels, ...tabs];
	}, []);

	return !loading && productInfo !== undefined ? (
		<ProductInfoContext.Provider value={productInfoValue}>
			<PimLanguageContext.Provider value={pimLanguageValue}>
				<PimSchemeDataContext.Provider value={schemeDataValue}>
					<div className="pim-title-container">
						<div className="pim-title">
							<strong>{props.sku}</strong> - {productInfo.details.title}
						</div>
						<div className="pim-title-buttons-cont">
							<div className="pim-title-buttons-box width100">
								<div className="pim-buttons">
									<button
										className="pim-title-button pim-green"
										onClick={onSubmit}
									>
										<img src="/icons/pim-saved.svg" alt="" />
										<div className="pim-title-button-text">SALVAR</div>
									</button>
									<button
										className="pim-title-button"
										onClick={() => setEditMetaForm(true)}
									>
										<small className="pim-title-button-text">META</small>
									</button>

									{/* <div className="filter-text">Variants:</div>
                <div className="ml2 mr2">
                  <Select className="pim-main-schemes-select" id="select" value="">
                    <MenuItem value="10">
                      <div className="in-row align-center">
                        <img className="mr2" src="/icons/arrow-return-right.svg" alt="" />
                        <div className="">
                          8130: Pack 3
                        </div>
                        <img className="ml2" src="/icons/nav-external-link.svg" alt="" />
                      </div>
                    </MenuItem>
                    <MenuItem value="20">
                      <div className="in-row align-center">
                        <img className="mr2" src="/icons/group-p.svg" alt="" />
                        <div className="">
                          8130: Pack 3
                        </div>
                        <img className="ml2" src="/icons/nav-external-link.svg" alt="" />
                      </div>
                    </MenuItem>
                    <MenuItem value="30">
                      <div className="in-row align-center">
                        <img className="mr2" src="/icons/arrow-return-right.svg" alt="" />
                        <div className="">
                          8130: Pack 3
                        </div>
                        <img className="ml2" src="/icons/nav-external-link.svg" alt="" />
                      </div>
                    </MenuItem>
                  </Select>
                </div> */}
									{!!productInfo.variantOfSku ? (
										<button
											className="pim-title-button"
											onClick={async (e) => {
												e.preventDefault();

												try {
													await pushProductVariants(productInfo.variantOfSku);
													toast.success("Parent product updated in shopify");
												} catch (error) {
													console.error(error);
													toast.error("Something went wrong!");
												}
											}}
										>
											<small className="pim-title-button-text">
												Push variant
											</small>
										</button>
									) : (
										<button
											className="pim-title-button"
											onClick={async (e) => {
												e.preventDefault();

												try {
													await pushProductVariants(productInfo.sku);
													toast.success("Product updated in shopify");
												} catch (error) {
													console.error(error);
													toast.error("Something went wrong!");
												}
											}}
										>
											<small className="pim-title-button-text">
												Push all variants
											</small>
										</button>
									)}
									{editMetaForm && (
										<EditProductInfoMeta
											productInfo={productInfo}
											modal={editMetaForm}
											setModal={setEditMetaForm}
											sendRequest={async (data) => {
												await Promise.all(
													countries.map(async (item: any) => {
														try {
															await updateCurrentProductInfo(data.sku, {
																...data,
																lang: item.langCode,
															});
														} catch (error) {
															console.error(error);
														}
													})
												);

												setProductInfo((prevState: any) => ({
													...prevState,
													...data,
												}));
											}}
										/>
									)}
									{/* <Select className="pim-count-select" labelId="label" id="select" value="0">
                <MenuItem value="0">
                  <div className="pim-language-text">9871: Principal</div>
                </MenuItem>
                <MenuItem value="1">
                  <div className="pim-language-text">8130: Pack 3</div>
                </MenuItem>
                <MenuItem value="2">
                  <div className="pim-language-text">9130: Pack 6</div>
                </MenuItem>
                <MenuItem value="3">
                  <div onClick={() => {
                    setModal(true);
                  }} className="pim-language-text">+ Inserir Nova Variante</div>
                </MenuItem>
              </Select> */}
								</div>
								<div className="pim-buttons">
									<Select
										className="pim-count-select mwidth150"
										labelId="label"
										id="select"
										value={pimLanguage}
										onChange={(e: any) => {
											e.preventDefault();
											setPimLanguage(e.target.value);
										}}
									>
										{langs.map((item: ICountryM, index: number) => (
											<MenuItem value={item.langCode} key={index}>
												<div className="in-row align-center">
													<img
														className="mr1"
														src={item.iconUrl}
														alt=""
														width="20px;"
													/>
													<div className="pim-language-text">
														{item.language}
													</div>
												</div>
											</MenuItem>
										))}
									</Select>
									<RoleRequired role={Role.admin}>
										<button
											className="pim-title-button"
											onClick={() => setVersionsForm(true)}
										>
											<img src="/icons/pim-history-line.svg" alt="" />
											<div className="pim-title-button-text normal">
												Versions
											</div>
										</button>
									</RoleRequired>
									{versionsForm && (
										<Modal
											onModalClose={(e: React.MouseEvent) => {
												e.stopPropagation();
												setVersionsForm(false);
											}}
											isOpened={versionsForm}
										>
											<div className="variants-modal-cont">
												<VersionsForm
													productInfo={productInfo}
													setProductInfo={(productVersion: any) => {
														productVersion.details =
															productVersion.details || {};
														setProductInfo(productVersion);
													}}
												/>
											</div>
										</Modal>
									)}
									<RoleRequired role={Role.admin}>
										<button className="pim-title-button">
											<img src="/icons/pim-trash-can.svg" alt="" />
										</button>
									</RoleRequired>
								</div>
							</div>
						</div>
					</div>
					<AppBar
						className="index1 pim-app-bar"
						position="static"
						color="default"
					>
						<AntTabs
							value={value}
							onChange={handleChange}
							variant="scrollable"
							scrollButtons="auto"
							aria-label="scrollable auto tabs example"
							className="pim-tabs-cont"
						>
							{antTab(schemeData).map((tab: string, index: number) => (
								<AntTab
									className="pim-tab-box"
									label={tab}
									{...a11yProps(index)}
									key={index}
								/>
							))}
						</AntTabs>
					</AppBar>
					<SwipeableViews
						axis={theme.direction === "rtl" ? "x-reverse" : "x"}
						index={value}
						onChangeIndex={handleChangeIndex}
						className="pim-main-bg"
					>
						{schemeLabels().map((label: string, index: number) => (
							<TabsPanel
								value={value}
								index={index}
								dir={theme.direction}
								key={index}
							>
								<ReusableTab
									schemeData={tabData(label)}
									productInfo={productInfo.details}
									onInputChange={onInputChange}
									onCheckboxListChange={onCheckboxListChange}
									onTagsChange={onTagsChange}
								/>
							</TabsPanel>
						))}
						<TabsPanel
							value={value}
							index={schemeLabels().length}
							dir={theme.direction}
						>
							<Costs
								productInfo={productInfo.details}
								onInputChange={onInputChange}
								combinedProduct={combinedProduct}
								onUpdateCallback={onUpdateCallback}
							/>
						</TabsPanel>
						<TabsPanel
							value={value}
							index={schemeLabels().length + 1}
							dir={theme.direction}
						>
							<ReviewsPim
								productInfo={productInfo.details}
								onInputChange={onInputChange}
								// reviews={reviews}
								sku={props.sku}
							/>
						</TabsPanel>
						<TabsPanel
							value={value}
							index={schemeLabels().length + 2}
							dir={theme.direction}
						>
							<Awards />
						</TabsPanel>
						{/* <TabsPanel
						value={value}
						index={schemeLabels().length + 3}
						dir={theme.direction}
					>
						<Testimonials />
					</TabsPanel> */}
					</SwipeableViews>
					{modal && (
						<Modal onModalClose={onModalClose} isOpened={modal}>
							<>
								<div className="">
									<div className="table-modal-title-box m0">
										<div className="pim-modal-title-ava">
											Inserir nova variante
										</div>
									</div>
									<form className="table-modal-form-cont">
										<div className="table-modal-border"></div>
										<div className="in-row width100 mb4">
											<div className="in-column width100 pl2 align-start justify-start">
												<div className="pim-modal-text">SKU:</div>
												<input
													className="pim-modal-input"
													required
													placeholder=""
												/>
											</div>
											<div className="in-column width100 pl2 align-start justify-start">
												<div className="pim-modal-text">Variante:</div>
												<select className="pim-count-select m0 mwidth200">
													<option className="pim-title-select-option" value="">
														_
													</option>
													<option className="" value="First">
														Portugal
													</option>
													<option className="" value="Second">
														Spain
													</option>
												</select>
											</div>
										</div>
										<div className="table-modal-border"></div>
										<div className="table-modal-form-button-box width100">
											<button className="table-modal-form-button-cancel">
												Cancel
											</button>
											<button className="table-modal-form-button">
												INSERIR
											</button>
										</div>
									</form>
								</div>
							</>
						</Modal>
					)}
				</PimSchemeDataContext.Provider>
			</PimLanguageContext.Provider>
		</ProductInfoContext.Provider>
	) : (
		<Preloader />
	);
}

export const EditProductInfoMeta: React.FC<
	Omit<IEditMetaInfoModal, "schemes">
> = (props) => {
	const [schemes, setSchemes] = useState<any>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const countries = useContext(CountriesContext) as ICountryM[];

	useEffect(() => {
		async function initializeState() {
			setSchemes(await getSchemes());
			setLoading(false);
		}
		initializeState();
		// eslint-disable-next-line
	}, []);

	if (loading) {
		return <Preloader />;
	}

	return (
		<EditMetaInfoModal
			{...props}
			schemes={schemes}
			sendRequest={async (data) => {
				await Promise.all(
					_.uniq(countries.map((x) => x.langCode)).map(async (lang: any) => {
						try {
							await updateProductMetaInfo({
								...data,
								lang,
							});
						} catch (error) {
							console.error(error);
						}
					})
				);
			}}
		/>
	);
};

export const VersionsForm: React.FC<any> = ({
	productInfo,
	setProductInfo,
}) => {
	const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
		perPage: 50,
		page: 1,
	});

	const {
		isLoading,
		data: versions,
		refetch,
	} = useQuery("versions", () =>
		getAllProductInfoVersions(productInfo.sku, productInfo.lang, currentConfig)
	);

	useEffect(() => {
		refetch();
		// eslint-disable-next-line
	}, [currentConfig]);

	const onChangePage = useCallback(async (config: IPaginatedRequest) => {
		setCurrentConfig(config);
		// eslint-disable-next-line
	}, []);

	const deleteMutation = useMutation(
		(id: string) => deleteProductInfoVersion(id),
		{
			onSuccess: () => {
				queryClient.invalidateQueries("reviews");
				setProductInfo(productInfo);
				toast.success("Version successfully deleted!");
			},
			onError: () => {
				toast.error("Something went wrong!");
			},
		}
	);

	const onDelete = useCallback(
		async (e: React.FormEvent | React.MouseEvent, id: string) => {
			e.preventDefault();

			await deleteMutation.mutateAsync(id);
		},
		[deleteMutation]
	);

	if (isLoading) {
		return (
			<div className="center-loader">
				<Preloader />
			</div>
		);
	}

	return (
		<div className="table-main-cont whitebg mheighti">
			<div className="version-table-overflow">
				<div className="table-main-title-cont">
					<div></div>
					<div className="marketplace-pagination">
						<Paginator data={versions} refetch={onChangePage as any} />
					</div>
				</div>
				<div className="width100">
					<table
						style={{ position: "sticky", zIndex: 101, top: "0", width: "100%" }}
					>
						<tr style={{ marginTop: "-5px" }} className="table-results-title">
							<th className="version-reg">Version</th>
							<th className="table-border-right"></th>
							<th className="version-reg">Date</th>
							<th className="table-border-right"></th>
							<th className="version-reg">Status</th>
							<th className="table-border-right"></th>
							<th className="version-reg">Author</th>
							<th className="table-border-right"></th>
							<th className="version-delete"></th>
						</tr>
					</table>
					<div style={{ marginTop: "-5px" }}>
						<table className="table-results p0">
							{/* <thead>
              <tr className="table-results-title">
                <th className="version-reg">Version</th>
                <th className="table-border-right"></th>
                <th className="version-reg">Date</th>
                <th className="table-border-right"></th>
                <th className="version-reg">Status</th>
                <th className="table-border-right"></th>
                <th className="version-reg">Author</th>
                <th className="table-border-right"></th>
                <th className="version-delete"></th>
              </tr>
            </thead> */}
							<tbody>
								{versions.data.map((productVersion: any) => (
									<tr
										style={{
											backgroundColor:
												productVersion._id !== productInfo._id
													? `transparent`
													: "#e4e4e4",
										}}
										className="table-result-box freight-body"
										key={productVersion._id}
										onClick={(e) => {
											e.preventDefault();
											setProductInfo(productVersion);
										}}
									>
										<td className="version-reg bold pointer">
											{productVersion.version}
										</td>
										<td className="table-border-right"></td>
										<td className="version-reg">
											{moment(productVersion.created).format(
												"MMM Do YYYY, hh:mm a"
											)}
										</td>
										<td className="table-border-right"></td>
										<td className="version-reg">
											{productVersion._id === productInfo._id && "current"}
										</td>
										<td className="table-border-right"></td>
										<td className="version-reg">
											{productVersion.editor?.username &&
												`Authored by ${productVersion.editor?.username}`}
										</td>
										<td className="table-border-right"></td>
										<td className="version-delete">
											{productVersion._id !== productInfo._id && (
												<img
													onClick={(e) => onDelete(e, productVersion._id)}
													className="version-delete-button"
													src="/icons/trash-bin.svg"
													alt=""
													width="20px;"
												/>
											)}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
};
