import React, { useState } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import * as _ from "lodash";
import { useQuery } from "react-query";
import FilterSelect from "../../../../ui/select/FilterSelect";
import SingleBatchTable from "./SingleBatchTable";
import { ICountryM } from "../../../country-management/CountryManagement";
import { getSanitizedMarketplaces } from "../../../../../actions/marketplace";
import { IMarketplace } from "../../../../../interfaces";
import CircularPreloader from "../../../../ui/circular-preloader/CircularPreloader";
import { getSingleBatch } from "../../../../../actions/batch";
import useCountries from "../../../../../hooks/useCountries";

type TParams = { id: string };

const SingleBatch: React.FC<RouteComponentProps<TParams>> = (props) => {
  const countries = useCountries() as ICountryM[];
	const { id } = props.match.params;

	const [platformName, setPlatformName] = useState<string>("All platforms");
	const [country, setCountry] = useState<string>("All countries");

	const { isLoading, data: sanitizedMarkeplaces } = useQuery(
		"sanitizedMarkeplaces",
		() => getSanitizedMarketplaces()
	);

	const { isLoading: loadSingleBatch, data: singleBatch } = useQuery(
		`singleBatch/${id}`,
		() => getSingleBatch(id)
	);

	if (loadSingleBatch) {
		return <></>;
	}

	return (
		<div className="main-body">
			<div className="main-container">
				<div className="table-main-cont">
					<div className="single-billing-nav pointer">
						<img src="/icons/billing-arrow-back.svg" alt="" width="25px;" />
						<Link to="/batch-list">
							<div className="table-subtext underline pl2">
								back to Batch List
							</div>
						</Link>
					</div>
					<div className="mplc-filter-cont width100 mb5">
						<div className="mplc-filter">
							<div className="filter-text">Filter by:</div>
							{isLoading ? (
								<CircularPreloader />
							) : (
								<FilterSelect
									onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
										setPlatformName(e.target.value)
									}
									value={platformName}
									data={[
										"All platforms",
										..._.uniq(
											_.map(
												sanitizedMarkeplaces,
												(item: IMarketplace) => item.zeoosName
											)
										),
									]}
								/>
							)}
							<FilterSelect
								onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
									setCountry(e.target.value)
								}
								value={country}
								data={[
									"All countries",
									..._.uniq(_.map(countries, (item: ICountryM) => item.name)),
								]}
							/>
						</div>
					</div>
					<SingleBatchTable
						marketplaces={singleBatch?.marketplaces}
						platformName={platformName}
						country={country}
					/>
				</div>
			</div>
		</div>
	);
};

export default withRouter(SingleBatch);
