import React, { useCallback, useEffect, useState } from "react";
import {
	IConfigPagination,
	IBatchMarkeplace,
	IBatchMProducts,
} from "../../../../../interfaces";
import Paginator from "../../../../ui/pagination";
import CountryFlag from "../../../../ui/country-flag/CountryFlag";
import BatchErrorsModal from "./BatchErrorsModal";

interface IPaginatedBatchMplc {
	data: IBatchMarkeplace[];
	lastPage: number;
	page: number;
	perPage: number;
	total: number;
}

interface Props {
	marketplaces: IBatchMarkeplace[];
	platformName: string;
	country: string;
}

const SingleBatchTable: React.FC<Props> = ({
	marketplaces,
	platformName,
	country,
}) => {
	const [errorModal, setErrorModal] = useState<boolean>(false);
	const [batchPlatform, setBatchPlatform] = useState<IBatchMarkeplace>(
		marketplaces[0]
	);
	const [search, setSearch] = useState<string>("");
	const [paginatedData, setPaginatedData] = useState<IPaginatedBatchMplc>({
		data: marketplaces,
		perPage: 50,
		page: 1,
		total: marketplaces.length,
		lastPage: Math.ceil(marketplaces.length / 50),
	});
	const [results, setResults] = useState<IBatchMarkeplace[]>(marketplaces);

	useEffect(() => {
		const _filter =
			platformName === "All platforms"
				? { ...paginatedData, data: marketplaces }
				: {
						...paginatedData,
						data: marketplaces.filter(
							(x: IBatchMarkeplace) => x.marketplace.zeoosName === platformName
						),
				  };
		const filtered =
			country === "All countries"
				? _filter
				: {
						...paginatedData,
						data: marketplaces.filter(
							(x: IBatchMarkeplace) => x.marketplace.country === country
						),
				  };

		setPaginatedData(filtered);
		// eslint-disable-next-line
	}, [platformName, country]);

	useEffect(() => {
		const results = marketplaces
			// eslint-disable-next-line
			.filter((item: any) => {
				if (search === "") {
					return item;
				} else if (
					Object.keys(item.marketplace).filter(
						(x: any) =>
							typeof item.marketplace[x] === "string" &&
							item.marketplace[x].toLowerCase().includes(search.toLowerCase())
					)?.length > 0
				) {
					return item;
				}
			});
		setResults(results);
		setPaginatedData({
			...paginatedData,
			data: results.slice(0, paginatedData.perPage),
		});
		// eslint-disable-next-line
	}, [search]);

	const onChangePage = useCallback(
		async (config: IConfigPagination) => {
			setPaginatedData((c: IPaginatedBatchMplc) => {
				const getData = () => {
					if (config.page === 1) {
						return marketplaces.slice(0, config.perPage);
					}

					return config.perPage * config.page > c.total
						? marketplaces.slice(config.perPage * c.page)
						: marketplaces.slice(config.perPage, config.perPage * config.page);
				};

				return {
					...c,
					page: config.page,
					perPage: config.perPage,
					lastPage: Math.ceil(c.total / config.perPage),
					data: getData(),
				};
			});
		},
		// eslint-disable-next-line
		[setPaginatedData]
	);

	const checkBatchErrors = (batchPlatform: IBatchMarkeplace) => {
		return batchPlatform.products.filter((x: IBatchMProducts) => x.error)
			.length;
	};

	return (
		<>
			<div className="table-main-title-cont">
				<div className="table-main-title-search-value-cont">
					<div className="search-box">
						<div className="search_form">
							<div className="table-search-box">
								<form
									className="table-search-form"
									onSubmit={(e) => e.preventDefault()}
								>
									<input
										className="table-search-input"
										placeholder="Search platform"
										value={search}
										onChange={(e) => setSearch(e.target.value)}
									/>
									<img
										className="table-search-image"
										src="/icons/search.svg"
										width="25px;"
										alt=""
									/>
								</form>
							</div>
						</div>
						<div className="table-main-title-text pl2">
							<span className="table-main-title-text">
								{results?.length} platforms
							</span>
						</div>
					</div>
				</div>
				<div className="marketplace-pagination">
					<Paginator data={paginatedData} refetch={onChangePage as any} />
				</div>
			</div>
			<div style={{ height: "max-content" }} className="table-overflow">
				<table
					style={{ position: "sticky", zIndex: 101, top: "2px", width: "100%" }}
				>
					<thead>
						<tr
							style={{ marginTop: "-5px", width: "100%" }}
							className="table-results-title"
						>
							<th className="batch-platform">Platform</th>
							<th className="batch-status">Country</th>
							<th className="batch-platform-name">Platform Name</th>
							<th className="batch-status">Upload Products</th>
							<th className="batch-status">Errors</th>
							<th className="batch-status">Status</th>
							<th className="batch-status">Actions</th>
						</tr>
					</thead>
				</table>
				<table
					className="table-results mb2 batch-text"
					style={{ minHeight: "auto" }}
				>
					<tbody>
						{paginatedData.data.map((batchPlatform: IBatchMarkeplace) => (
							<tr
								className="table-result-box height100px"
								key={batchPlatform._id}
							>
								<td className="batch-platform">
									<img
										className="batch-image-border"
										height={84}
										width={84}
										src={`https://vinuus-portal.s3.eu-west-3.amazonaws.com/production/assets/${batchPlatform.marketplace.marketplaceName}.svg`}
										alt=""
									/>
								</td>
								<td className="batch-status">
									<CountryFlag
										country={batchPlatform.marketplace.country}
										className="table-search-image"
										width="35px"
									/>
								</td>
								<td className="batch-platform-name">
									<span>{batchPlatform.marketplace.zeoosName}</span>
								</td>
								<td className="batch-status">
									<span>{batchPlatform.products.length}</span>
								</td>
								<td className="batch-status">
									{checkBatchErrors(batchPlatform) ? (
										<span className="red bold underline">
											{checkBatchErrors(batchPlatform)} errors
										</span>
									) : (
										<></>
									)}
								</td>
								<td className="batch-status">
									<div
										className={`batch-${batchPlatform.status.toLowerCase()}`}
									>
										{batchPlatform.status}
									</div>
								</td>
								<td className="batch-status">
									{checkBatchErrors(batchPlatform) ? (
										<div
											className="underline pointer"
											onClick={(e) => {
												e.preventDefault();
												setBatchPlatform(batchPlatform);
												setErrorModal(true);
											}}
										>
											See errors
										</div>
									) : (
										<></>
									)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
				{errorModal && (
					<BatchErrorsModal
						modal={errorModal}
						setModal={setErrorModal}
						batchPlatform={batchPlatform}
					/>
				)}
			</div>
		</>
	);
};

export default SingleBatchTable;
