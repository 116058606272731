import React, { useContext } from 'react';
import { IZeoosOrder } from '../../../../../interfaces';
import { CountriesContext } from '../../../../context';
import { ICountryM } from '../../../country-management/CountryManagement';

interface Props {
    order: IZeoosOrder;
}
  
const SingleOrderDetail: React.FC<Props> = ({order}) => {
    const countries = useContext(CountriesContext) as ICountryM[];
    
    return (
        <>
            <div className='single-order-title-cont'>
                <h2 className='single-order-title2'>Order Details</h2>
                <div className='in-row align-center'>
                    {/* TODO */}
                    {/* <img className='mr2 pointer' src='/icons/order-print.svg' alt='' /> */}
                </div>
            </div>
            <div className='order-border'></div>
            <div className='so-details-cont'>
                <div className='so-details-box single-order-text'>
                    <b>ID ERP</b>
                    <span>{order.order_erp}</span>
                </div>
                <div className='so-details-box single-order-text'>
                    <b>ID Platform</b>
                    <span>{order.order_marketplace}</span>
                </div>
                <div className='so-details-box single-order-text'>
                    <b>Platform Name</b>
                    <div className='in-row align-cener'>
                        {countries.find((x: any) => order.customer?.country === x.name) ? (
                            <img                            
                            src={
                                countries.find((x: any) => order.customer?.country === x.name)
                                ?.iconUrl
                            }
                            width="24px"
                            alt=""
                            />
                        ) : (
                            <span>{order.customer?.country}</span>
                        )}
                        <span className='pl2' style={{width:"max-content"}}>{order.zeoosName}</span>
                    </div>
                </div>
                <div className='so-details-box single-order-text'>
                    <b>Order Date</b>
                    <span>{order.date}</span>
                </div>
                <div className='so-details-box single-order-text'>
                    <b>Status Order</b>
                    <span>{order.status}</span>
                </div>
                
            </div>
        </>
    );
}

export default SingleOrderDetail;
